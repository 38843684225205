import React from "react";
import { Descriptions, Typography } from "antd";
const { Title } = Typography;

const AnswerBotAgentSetup = ({ resource }) => {
  return (
    <>
      <Title level={4}>Order Information</Title>
      <Descriptions column={1} layout="vertical" size="small">
        <Descriptions.Item label="Agent Name">{resource.agent_name}</Descriptions.Item>
        <Descriptions.Item label="Agent ID">{resource.agent_id}</Descriptions.Item>
        <Descriptions.Item label="Country">{resource.country}</Descriptions.Item>
        <Descriptions.Item label="Preferred Area Codes">{resource.preferred_area_codes}</Descriptions.Item>
      </Descriptions>
    </>
  );
};

export default AnswerBotAgentSetup;
