import _ from 'lodash';
import React from "react"
import { Card } from "antd";
import StatusTag from '../shared/StatusTag';
import PrArticle from './resources/PrArticle';
import SboOrder from './resources/SboOrder';
import SaatchiAccount from './resources/SaatchiAccount';
import LsaOrder from './resources/LsaOrder';
import GbpOptimization from './resources/GbpOptimization';
import WebsiteSetup from './resources/WebsiteSetup';
import SeoV2Order from './resources/SeoV2Order';
import AnswerBotAgentSetup from './resources/AnswerBotAgentSetup';
import AnswerBotPhoneSetup from './resources/AnswerBotPhoneSetup';
import AnswerBotKnowledgeFileUpload from './resources/AnswerBotKnowledgeFileUpload';

const OrderInfo = ({ order }) => (
  <Card title={<>{order.number} <StatusTag status={order.state} /></>}>
    {order.kind === 'pr_article' && <PrArticle resource={order.resource} />}
    {order.kind === 'sbo' && <SboOrder resource={order.resource} />}
    {order.kind === 'saatchi' && <SaatchiAccount resource={order.resource} />}
    {order.kind === 'lsa' && <LsaOrder resource={order.resource} />}
    {order.kind === 'gbp_optimization' && <GbpOptimization resource={order.resource} />}
    {order.kind === 'website_setup' && <WebsiteSetup resource={order.resource} />}
    {order.kind === 'seo_v2' && <SeoV2Order resource={order.resource} />}
    {order.kind === 'answer_bot_agent_setup' && <AnswerBotAgentSetup resource={order.resource} />}
    {order.kind === 'answer_bot_phone_setup' && <AnswerBotPhoneSetup resource={order.external_payload} />}
    {order.kind === 'answer_bot_knowledge_file_upload' && <AnswerBotKnowledgeFileUpload resource={order.external_payload} />}
  </Card>
)

export default OrderInfo;
